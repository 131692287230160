.successMessage {
	font-size: 80%;
	color: green;
}

.errorMessage {
	font-size: 80%;
	color: red;
}

.required {
	font-size: 80%;
	color: red;
	margin: 2px 10px;
}

.optional {
	font-size: 80%;
	color: gray;
	margin: 2px 10px;
}

rt {
  font-size: 0.8em;
}